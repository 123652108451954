import "./styles.scss"

import React, { useState } from "react"
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api"
import { withPrefix } from "gatsby"
import LazyLoad from "react-lazyload"

import InvestmentMapItem from "components/InvestmentMapItem"

import mapStyle from "utils/mapStyle"

const Map = ({ zoom = 9, data }) => {
  const [infoWindow, setInfoWindow] = useState(null)
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDjIwkgdwELw8D7YSGfklubIKQyUl-OQy4",
  })

  const center = {
    lat: data?.mapContent?.latitude,
    lng: data?.mapContent?.longitude,
  }

  return (
    data?.mapContent && (
      <>
        <span className="anchor" id="location"></span>
        <section className="investment-map">
          <div className="container-fluid">
            {data?.mapHeader && <h2>{data?.mapHeader}</h2>}
            {isLoaded ? (
              <LazyLoad>
                <div id="map">
                  <GoogleMap
                    center={center}
                    zoom={zoom}
                    options={{ styles: mapStyle }}
                  >
                    <Marker
                      icon={withPrefix("/ico-pointer-gold.svg")}
                      position={center}
                    />
                    {data?.mapPointers?.map((item, index) => (
                      <Marker
                        key={index}
                        onClick={() => setInfoWindow(item?.name)}
                        onMouseOver={() => setInfoWindow(item?.name)}
                        icon={withPrefix("/ico-pointer.svg")}
                        position={{
                          lat: item?.location?.latitude,
                          lng: item?.location?.longitude,
                        }}
                      />
                    ))}
                    {data?.mapPointers
                      ?.filter(item => item?.name === infoWindow)
                      .map((item, index) => (
                        <InfoWindow
                          key={index}
                          onCloseClick={() => setInfoWindow(null)}
                          position={{
                            lat: item?.location?.latitude,
                            lng: item?.location?.longitude,
                          }}
                        >
                          <InvestmentMapItem item={item} />
                        </InfoWindow>
                      ))}
                  </GoogleMap>
                </div>
              </LazyLoad>
            ) : null}
            {data?.mapLocations && (
              <div className="investment-map__locations">
                {data?.mapLocations?.map((item, index) => (
                  <div className="investment-map__item" key={index}>
                    <small>{item.description}</small>
                    <span>
                      {item?.icon?.localFile?.publicURL && (
                        <img src={item?.icon?.localFile?.publicURL} alt="" />
                      )}{" "}
                      <p>{item.duration}</p>
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      </>
    )
  )
}

export default Map
