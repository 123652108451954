import "./styles.scss"

import React from "react"
import Slider from "react-slick"
import scrollToElement from "scroll-to-element"

import FavoriteCompare from "components/FavoriteCompare"
import Button from "components/Button"
import ContactFormInvestment from "components/ContactFormInvestment"

import IcoApartmens from "assets/icons/apartments.svg"
import IcoSizes from "assets/icons/sizes.svg"
import IcoPricing from "assets/icons/pricing.svg"
import IcoStatus from "assets/icons/status.svg"

const Hero = ({ id, data, title, region, slug }) => {
  const slider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <section className="investment-hero">
      <div className="container-fluid">
        <div className="investment-hero__row">
          <div className="investment-hero__row-inner">
            {data?.logo?.localFile?.publicURL && (
              <img
                className="investment-hero__row-logo"
                src={data?.logo?.localFile?.publicURL}
                alt=""
              />
            )}
            <div className="investment-hero__info">
              <h1>{title}</h1>
              {data?.address && <address>{data?.address}</address>}
            </div>
          </div>

          <div className="investment-hero__region">
            <div>
              <small>Region inwestycji:</small>
              <p>{region?.label}</p>
            </div>
            <img
              src={
                require(`assets/images/investments/investment-hero-icon-${data?.region}.svg`)
                  .default
              }
              alt=""
            />
          </div>
        </div>

        {data?.status !== "sell" && (
          <div className="investment-hero__baner">
            <p>
              Sprzedaż tej inwestycji został już zakończona. Sprawdź naszą
              aktualną ofertę inwestycji na sprzedaż!
            </p>
            <Button to="/portfel-nieruchomosci/">Zobacz ofertę</Button>
          </div>
        )}

        <div className="row justify-content-center">
          <div className="col-xl-11">
            <div className="investment-hero__data">
              <div>
                <div className="investment-hero__data-item">
                  <img src={IcoApartmens} alt="" />
                  <p>
                    <small>LICZBA APARTAMENTÓW:</small>{" "}
                    {data?.apartmensNumber ? data?.apartmensNumber : "-"}
                  </p>
                </div>
                <div className="investment-hero__data-item">
                  <img src={IcoSizes} alt="" />
                  <p>
                    <small>METRAŻ:</small>{" "}
                    {data?.areaNumberMin ? data?.areaNumberMin : "-"}m
                    <sup>2</sup>-
                    {data?.areaNumberMax ? data?.areaNumberMax : ""}m
                    <sup>2</sup>
                  </p>
                </div>
                {data?.status !== "done" && (
                  <div className="investment-hero__data-item">
                    <img src={IcoPricing} alt="" />
                    <p>
                      <small>CENA NETTO OD:</small>{" "}
                      {data?.apartmenPriceMin
                        ? data?.apartmenPriceMin.toLocaleString()
                        : "-"}{" "}
                      zł
                    </p>
                  </div>
                )}
                <div className="investment-hero__data-item">
                  <img src={IcoStatus} alt="" />
                  <p>
                    <small>Status inwestycji:</small>{" "}
                    {data?.status === "sell" ? "w sprzedaży" : "zrealizowane"}
                  </p>
                </div>
              </div>
              {data?.status !== "done" && (
                <FavoriteCompare id={id} type="investment" />
              )}
            </div>
          </div>
        </div>
      </div>

      {data?.images && (
        <div className="investment-hero__slider">
          <Slider {...slider}>
            {data?.images?.map((item, index) => (
              <div key={index}>
                <div
                  className="investment-hero__slide"
                  style={{
                    backgroundImage: `url('${item?.localFile?.publicURL}')`,
                  }}
                ></div>
              </div>
            ))}
          </Slider>
          <div className="investment-hero__slider-wrapper">
            <div className="container-fluid">
              <div className="investment-hero__slider-row">
                {data?.heroSlogan && (
                  <div>
                    <h1>
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.heroSlogan }}
                      />
                    </h1>
                    <Button
                      className="button--bordered"
                      onClick={() => scrollToElement("#about")}
                    >
                      więcej
                    </Button>
                  </div>
                )}
                {data?.showForm && (
                  <div className="investment-hero__form">
                    <div className="investment-hero__form-inner">
                      <h3>Skontaktuj się z nami</h3>
                      <ContactFormInvestment investment={title} slug={slug} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default Hero
