import "./styles.scss"

import React, { useState } from "react"
import ReactPlayer from "react-player"
import LazyLoad from "react-lazyload"

import IcoPlay from "assets/icons/play-fill.svg"

const Video = ({ data }) => {
  const [play, setPlay] = useState(false)

  return (
    data?.videoYoutube && (
      <section className="investment-video">
        <div className="container-fluid">
          <h2>{data.videoHeader}</h2>
          <div
            className={`investment-video__wrapper${
              play ? " investment-video__wrapper--playing" : ""
            }`}
          >
            <LazyLoad>
              <ReactPlayer
                url={data?.videoYoutube}
                width="100%"
                heigth="100%"
                loop={false}
                playing={play}
                controls={true}
                playsinline={true}
              />
            </LazyLoad>
            {!play && (
              <button onClick={() => setPlay(true)}>
                <img src={IcoPlay} alt="" />
              </button>
            )}
          </div>
        </div>
      </section>
    )
  )
}

export default Video
