import "./styles.scss"

import React from "react"
import scrollToElement from "scroll-to-element"
import Scrollspy from "react-scrollspy"

const Nav = ({ title, data }) => {
  return (
    <section className="investment-nav">
      <div className="container-fluid">
        <div className="investment-nav__wrapper">
          <h4>{title}</h4>
          <nav>
            <Scrollspy
              items={[
                "about",
                "building",
                "inside",
                "why",
                "location",
                data?.status !== "done" && "apartments",
                "gallery",
                data?.status !== "done" && "news",
              ]}
              currentClassName="is-active"
              offset={78}
            >
              <button onClick={() => scrollToElement("#about")}>
                O inwestycji
              </button>
              <button onClick={() => scrollToElement("#building")}>
                Budynek
              </button>
              <button onClick={() => scrollToElement("#inside")}>
                Wnętrze
              </button>
              <button onClick={() => scrollToElement("#why")}>
                Dlaczego warto
              </button>
              <button onClick={() => scrollToElement("#location")}>
                Lokalizacja
              </button>
              {data?.status !== "done" && (
                <button onClick={() => scrollToElement("#apartments")}>
                  Apartamenty
                </button>
              )}
              <button onClick={() => scrollToElement("#gallery")}>
                Galeria
              </button>
              {data?.status !== "done" && (
                <button onClick={() => scrollToElement("#news")}>
                  Aktualności
                </button>
              )}
              <button onClick={() => scrollToElement("#contact")}>
                Skontaktuj się
              </button>
            </Scrollspy>
          </nav>
        </div>
      </div>
    </section>
  )
}

export default Nav
