import "./styles.scss"

import React from "react"
import Slider from "react-slick"

import LazyFadeImage from "components/LazyFadeImage"

const Gallery = ({ data }) => {
  const slider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  return (
    data?.galleryContent && (
      <>
        <span className="anchor" id="gallery"></span>
        <section className="investment-gallery">
          <div className="container-fluid">
            {data?.galleryHeader && <h2>{data?.galleryHeader}</h2>}
            <Slider {...slider} className="slick-slider--arrows-big">
              {data?.galleryContent?.map((item, index) => (
                <div key={index}>
                  <LazyFadeImage>
                    <div
                      className="investment-gallery__slide"
                      style={{
                        backgroundImage: `url('${item?.localFile?.publicURL}')`,
                      }}
                    ></div>
                  </LazyFadeImage>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </>
    )
  )
}

export default Gallery
