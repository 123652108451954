import "./styles.scss"

import React from "react"
import Slider from "react-slick"

import LazyFadeImage from "components/LazyFadeImage"

const Grid = ({ data }) => {
  const slider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <section className="investment-grid">
      <div className="container-fluid">
        <span className="anchor" id="building"></span>
        <span className="anchor" id="inside"></span>
        {data?.buildingDescription && (
          <div className="row">
            <div className="col-xl-6 col-md-6">
              <LazyFadeImage height={630}>
                <div
                  className="investment-grid__image"
                  style={{
                    backgroundImage: `url('${data?.buildingImage?.localFile?.publicURL}')`,
                  }}
                ></div>
              </LazyFadeImage>
            </div>
            <div className="col-xl-5 offset-xl-1 col-md-6">
              <div
                dangerouslySetInnerHTML={{ __html: data?.buildingDescription }}
              />
            </div>
          </div>
        )}
        {data?.insideDescription && (
          <div className="row">
            <div className="col-xl-5 col-md-6">
              <div
                dangerouslySetInnerHTML={{ __html: data?.insideDescription }}
              />
            </div>
            <div className="col-xl-6 offset-xl-1 col-md-6">
              <Slider {...slider}>
                {data?.insideImages?.map((item, index) => (
                  <div key={index}>
                    <LazyFadeImage height={630}>
                      <div
                        className="investment-grid__slide"
                        style={{
                          backgroundImage: `url('${item?.localFile?.publicURL}')`,
                        }}
                      ></div>
                    </LazyFadeImage>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Grid
