import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const About = ({ data }) => {
  return (
    data?.aboutDescription && (
      <>
        <span className="anchor" id="about"></span>
        <section className="investment-about">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-5 col-md-6">
                <div
                  dangerouslySetInnerHTML={{ __html: data?.aboutDescription }}
                />
              </div>

              <div className="col-xl-6 offset-xl-1 col-md-6">
                <LazyFadeImage>
                  <div
                    className="investment-about__image"
                    style={{
                      backgroundImage: `url('${data?.aboutImage?.localFile?.publicURL}')`,
                    }}
                  />
                </LazyFadeImage>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  )
}

export default About
