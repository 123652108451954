import "./styles.scss"

import React from "react"

import IcoTime from "assets/icons/time-timeline.svg"
import IcoBuild from "assets/icons/build-timeline.svg"
import IcoBuilding from "assets/icons/building-timeline.svg"

const Timeline = ({ data }) => {
  return (
    data?.timeline && (
      <section className="investment-timeline">
        <div className="container-fluid">
          {data?.timeline && (
            <div className="investment-timeline__timeline">
              {data?.timelineProgress && (
                <div className="investment-timeline__timeline-progress">
                  <strong style={{ left: `${data?.timelineProgress}%` }}>
                    {data?.timelineProgress}%
                  </strong>
                  <span style={{ width: `${data?.timelineProgress}%` }} />
                </div>
              )}
              {data?.timeline?.map((item, index) => (
                <div className="investment-timeline__item" key={index}>
                  <div className="investment-timeline__item-icon">
                    <img
                      src={
                        index === 0
                          ? IcoTime
                          : index === 1
                          ? IcoBuild
                          : IcoBuilding
                      }
                      alt=""
                    />
                  </div>
                  <small>{item.description}</small>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    )
  )
}

export default Timeline
