import "./styles.scss"

import React from "react"

const Tiles = ({ data }) => {
  return (
    data?.tilesContent &&
    data?.status === "sell" && (
      <section className="investment-tiles">
        <div className="container-fluid">
          {data?.tilesHeader && <h2>{data?.tilesHeader}</h2>}
          <div className="row justify-content-center">
            {data?.tilesContent?.map((item, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div
                  className={`investment-tiles__item investment-tiles__item--${index}`}
                >
                  <div className="investment-tiles__item-icon">
                    <img src={item?.icon?.localFile?.publicURL} alt="" />
                  </div>
                  <h3>{item.header}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  )
}

export default Tiles
