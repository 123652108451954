import "./styles.scss"

import React from "react"

const Convenients = ({ data }) => {
  return (
    data?.convenientsContent && (
      <>
        <span className="anchor" id="why"></span>
        <section className="investment-convenients">
          <div className="container-fluid">
            {data?.convenientsHeader && <h2>{data?.convenientsHeader}</h2>}
            <div className="row justify-content-center">
              {data?.convenientsContent?.map((item, index) => (
                <div className="col-lg-3 col-md-4 col-6" key={index}>
                  <div className="investment-convenients__item">
                    <div className="investment-convenients__item-icon">
                      <img src={item?.icon?.localFile?.publicURL} alt="" />
                    </div>
                    {item.header && <strong>{item.header}</strong>}
                    {item.description && <p>{item.description}</p>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    )
  )
}

export default Convenients
