import "./styles.scss"

import React, { useState, useEffect } from "react"

import Input from "components/Input"
import Button from "components/Button"
import Select from "components/Select"

import { Grid } from "./components"
import ApartmentsTable from "components/ApartmentsTable"

import { useApartments } from "context/apartments"

const PER_PAGE = 9

const Apartments = ({ data, dataAcf }) => {
  const { apartments } = useApartments()
  const [investmentApartments, setInvestmentApartments] = useState(null)
  const [view, setView] = useState("listing")
  const [sort, setSort] = useState(null)
  const [skip, setSkip] = useState(9)

  useEffect(() => {
    setInvestmentApartments(
      apartments.filter(
        item => item?.acfApartment?.investment[0]?.id === data?.id
      )
    )
  }, [data?.id, apartments])

  const [apartmentFilter, setApartmentFilter] = useState({
    areaStart: [],
    areaEnd: 200,
    roomsStart: [],
    roomsEnd: 10,
    floorsStart: [],
    floorsEnd: 10,
    status: [],
  })

  let filteredApartments = investmentApartments

  const apartmentsFiltering = () => {
    filteredApartments = investmentApartments
      ?.filter(item => {
        const floor = parseInt(
          item.acfApartment.floor ? item.acfApartment.floor : 0
        )

        if (
          item.acfApartment.area >= apartmentFilter.areaStart &&
          item.acfApartment.area <= apartmentFilter.areaEnd &&
          item.acfApartment.roomsnumber >= apartmentFilter.roomsStart &&
          item.acfApartment.roomsnumber <= apartmentFilter.roomsEnd &&
          floor >= apartmentFilter.floorsStart &&
          floor <= apartmentFilter.floorsEnd &&
          (apartmentFilter?.status?.includes(item?.acfApartment?.status) ||
            apartmentFilter?.status?.length === 0)
        ) {
          return item
        }
        return false
      })
      .sort((a, b) =>
        sort === "area_asc"
          ? a.acfApartment.area - b.acfApartment.area
          : sort === "area_desc"
          ? b.acfApartment.area - a.acfApartment.area
          : sort === "rooms_asc"
          ? a.acfApartment.roomsnumber - b.acfApartment.roomsnumber
          : sort === "rooms_desc"
          ? b.acfApartment.roomsnumber - a.acfApartment.roomsnumber
          : sort === "floor_asc"
          ? a.acfApartment.floor - b.acfApartment.floor
          : sort === "floor_desc"
          ? b.acfApartment.floor - a.acfApartment.floor
          : a.acfApartment.area - b.acfApartment.area
      )
      .slice(0, skip)
  }

  return (
    data?.acfInvestment?.status !== "done" &&
    filteredApartments?.length > 0 && (
      <>
        <span className="anchor" id="apartments"></span>
        {apartmentsFiltering()}
        <section className="investment-apartments">
          <div className="container-fluid">
            <h2>
              {dataAcf?.apartmentsHeader
                ? dataAcf?.apartmentsHeader
                : "Lista apartamentów"}
            </h2>
            <div className="investment-apartments__filters">
              <Input
                type="number"
                className="input--bordered"
                label="Metraż od"
                placeholder={`${data?.acfInvestment?.areaNumberMin || "-"} m2`}
                onChange={e => {
                  setApartmentFilter({
                    ...apartmentFilter,
                    areaStart: e.target.value,
                  })
                }}
              />
              <Input
                type="number"
                className="input--bordered"
                label="Metraż do"
                placeholder={`${data?.acfInvestment?.areaNumberMax || "-"} m2`}
                onChange={e =>
                  setApartmentFilter({
                    ...apartmentFilter,
                    areaEnd: e.target.value ? e.target.value : 200,
                  })
                }
              />
              <Input
                type="number"
                className="input--bordered"
                label="Liczba pokoi od"
                placeholder={data?.acfInvestment?.roomsNumberMin || "-"}
                onChange={e => {
                  setApartmentFilter({
                    ...apartmentFilter,
                    roomsStart: e.target.value,
                  })
                }}
              />
              <Input
                type="number"
                className="input--bordered"
                label="Liczba pokoi do"
                placeholder={data?.acfInvestment?.roomsNumberMax || "-"}
                onChange={e =>
                  setApartmentFilter({
                    ...apartmentFilter,
                    roomsEnd: e.target.value ? e.target.value : 10,
                  })
                }
              />
              <Input
                type="number"
                className="input--bordered"
                label="Piętro od"
                placeholder={data?.acfInvestment?.floorsNumberMin || "-"}
                onChange={e => {
                  setApartmentFilter({
                    ...apartmentFilter,
                    floorsStart: e.target.value,
                  })
                }}
              />
              <Input
                type="number"
                className="input--bordered"
                label="Piętro do"
                placeholder={data?.acfInvestment?.floorsNumberMax || "-"}
                onChange={e =>
                  setApartmentFilter({
                    ...apartmentFilter,
                    floorsEnd: e.target.value ? e.target.value : 10,
                  })
                }
              />
              <Select
                className="no-m"
                label="Status"
                options={[
                  { label: "Wszystkie", value: [] },
                  { label: "Wolne", value: "available" },
                  { label: "Zarezerwowane", value: "reserved" },
                  { label: "Sprzedane", value: "sold" },
                ]}
                onChange={e =>
                  setApartmentFilter({
                    ...apartmentFilter,
                    status: e.target.value,
                  })
                }
              />
            </div>

            <div className="investment-apartments__row">
              <div className="investment-apartments__cta">
                <Button
                  className={view !== "listing" ? "button--bordered" : ""}
                  onClick={() => setView("listing")}
                >
                  Widok listy
                </Button>
                <Button
                  className={view !== "grid" ? "button--bordered" : ""}
                  onClick={() => setView("grid")}
                >
                  Widok kafelków
                </Button>
              </div>
              <div className="investment-apartments__sort">
                <h5>Sortuj:</h5>
                <Select
                  className="no-m"
                  onChange={e => setSort(e.target.value)}
                  options={[
                    { label: "Metraż: od najmniejszego", value: "area_asc" },
                    { label: "Metraż: od największego", value: "area_desc" },
                    {
                      label: "Pokoje: od największej ilości",
                      value: "rooms_desc",
                    },
                    {
                      label: "Pokoje: od najmniejszej ilości",
                      value: "rooms_asc",
                    },
                    { label: "Piętro: od najwyższego", value: "floor_desc" },
                    { label: "Piętro: od najniższego", value: "floor_asc" },
                  ]}
                />
              </div>
            </div>

            {view === "grid" ? (
              <Grid data={filteredApartments} />
            ) : (
              <ApartmentsTable data={filteredApartments} />
            )}

            {skip < investmentApartments?.length && (
              <div className="investment-apartments__more">
                <Button onClick={() => setSkip(skip + PER_PAGE)}>
                  Pokaż więcej
                </Button>
              </div>
            )}
          </div>
        </section>
      </>
    )
  )
}

export default Apartments
