import "./styles.scss"

import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import LazyLoad from "react-lazyload"

import NewsTile from "components/NewsTile"

const News = ({ id, data, allWpPost }) => {
  const [filteredData, setFilteredData] = useState(allWpPost)

  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow:
      filteredData?.length >= 3 ? 3 : filteredData?.length === 2 ? 2 : 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: filteredData.length === 1 ? 1 : 2,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          adaptiveHeight: true,
        },
      },
    ],
  }

  useEffect(() => {
    setFilteredData(
      allWpPost?.filter(item =>
        item?.acfPost?.showInvestments?.find(el => el.id === id)
      )
    )
  }, [id, allWpPost])

  return (
    data?.status !== "done" &&
    filteredData?.length > 0 && (
      <>
        <span className="anchor" id="news"></span>
        <section className="investment-news">
          <div className="container-fluid">
            <div className="investment-news__row">
              <h2>Aktualności na temat inwestycji</h2>
            </div>
            <Slider {...slider}>
              {filteredData?.map((item, index) => (
                <div key={index}>
                  <LazyLoad>
                    <NewsTile item={item} />
                  </LazyLoad>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </>
    )
  )
}

export default News
