import "./styles.scss"

import React from "react"

import ApartmentTile from "components/ApartmentTile"

const Grid = ({ data }) => {
  return (
    <div className="investment-apartments-grid">
      <div className="row">
        {data?.map((item, index) => (
          <div className="col-md-4 col-sm-6" key={index}>
            <ApartmentTile item={item} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Grid
