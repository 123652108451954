import "./styles.scss"

import React from "react"

const Icons = ({ data }) => {
  return (
    !data?.iconsHideSection && (
      <section className="investment-icons">
        <div className="container-fluid">
          {data?.iconsHeader && <h2>{data?.iconsHeader}</h2>}
          <div className="row">
            {data?.iconsContent?.map((item, index) => (
              <div className="col-lg-3 col-md-4 col-6" key={index}>
                <div className="investment-icons__item">
                  <div className="investment-icons__item-icon">
                    <img src={item?.icon?.localFile?.publicURL} alt="" />
                  </div>
                  {item.header && <strong>{item.header}</strong>}
                  {item.description && <p>{item.description}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  )
}

export default Icons
