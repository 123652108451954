import "./styles.scss"

import React from "react"
import Slider from "react-slick"

import InvestmentTile from "components/InvestmentTile"

const Offer = ({ data, allWpInvestment }) => {
  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow:
      allWpInvestment.length > 3
        ? 3
        : allWpInvestment.length === 2
        ? allWpInvestment.length
        : 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: allWpInvestment.length === 1 ? 1 : 2,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          adaptiveHeight: true,
        },
      },
    ],
  }

  return (
    data?.status === "done" && (
      <>
        <section className="investment-offer">
          <div className="container-fluid">
            <h2>Aktualne oferty</h2>
            <Slider {...slider}>
              {allWpInvestment?.map((item, index) => (
                <div key={index}>
                  <InvestmentTile variant="grid" item={item} />
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </>
    )
  )
}

export default Offer
