import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const NewsTile = ({ item }) => {
  return (
    <Link
      to={`/aktualnosci${item?.uri}?category=${item?.categories?.nodes[0]?.slug}`}
      className="news-tile"
    >
      <div className="news-tile__image">
        <div
          style={{
            backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
          }}
        ></div>
        <span>Zobacz</span>
      </div>
      <h4>{item?.title}</h4>
      <small>{item?.date.split("T")[0].split("-").reverse().join(".")}</small>
      <div dangerouslySetInnerHTML={{ __html: item?.excerpt }} />
    </Link>
  )
}

export default NewsTile
