import "./styles.scss"

import React from "react"

const AboutWithBg = ({ data }) => {
  return (
    data?.aboutWithBackgroundDescription && (
      <>
        <span className="anchor" id="about"></span>
        <section
          id="about"
          className="investment-about-bg"
          style={{
            backgroundImage: `url('${data?.aboutWithBackgroundImage?.localFile?.publicURL}')`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 col-md-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.aboutWithBackgroundDescription,
                  }}
                />
              </div>
              <div className="col-xl-7 offset-xl-1 col-md-7">
                {data?.iconsHeader && <h2>{data?.iconsHeader}</h2>}
                <div className="row">
                  {data?.iconsContent?.map((item, index) => (
                    <div className="col-lg-4 col-6" key={index}>
                      <div className="investment-about-bg__item">
                        <div className="investment-about-bg__item-icon">
                          <img src={item?.icon?.localFile?.publicURL} alt="" />
                        </div>
                        {item.header && <strong>{item.header}</strong>}
                        {item.description && <p>{item.description}</p>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  )
}

export default AboutWithBg
